const handleResponse = (res) => {
  if (res.status >= 200 && res.status < 300) {
    return Promise.resolve(res.json())
  }

  try {
    const json = res.json()
    throw new Error(json.error)
  } catch (err) {
    throw new Error('Algo deu errado, tente novamente!')
  }
}

export const VannaService = {
  async getCheckout({ checkoutId, merchantId }) {
    return fetch(`${process.env.PREACT_APP_SERVER_URL}/selfCheckouts/load`, {
      method: 'POST',
      body: [JSON.stringify({ checkoutId, merchantId })],
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(handleResponse)
      .catch((err) => {
        throw err
      })
  },
  async processPayment(body) {
    return fetch(`${process.env.PREACT_APP_SERVER_URL}/selfCheckouts/processPayment`, {
      method: 'POST',
      body: [JSON.stringify(body)],
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(handleResponse)
      .catch((err) => {
        throw err
      })
  },
  async getBankslipPDF(body) {
    return fetch(`${process.env.PREACT_APP_SERVER_URL}/selfCheckouts/getBankslipPDF`, {
      method: 'POST',
      body: [JSON.stringify(body)],
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(handleResponse)
      .catch((err) => {
        throw err
      })
  },
}
