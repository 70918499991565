import { useCallback, useState, useEffect } from 'preact/hooks'
import { Router, route } from 'preact-router'
import Match from 'preact-router/match'
import queryString from 'query-string'
import usePromise from 'react-fetch-hook/usePromise'
import styled from 'styled-components'
import Helmet from 'preact-helmet'
import { postMessage } from './messages/postMessage'

import { VannaService } from './VannaService'

import { LayoutCheckout } from './components/LayoutCheckout'
import { HeaderSecurity } from './components/HeaderSecurity'
import { LazyRoute } from './components/LazyRoute'

import { CheckoutContext } from './CheckoutContext'

import './index.css'
import './index-reset.css'

const getParams = (url) => {
  const [, params] = url.split('?')

  if (!params) return {}

  return queryString.parse(params)
}

const Wrapper = styled.div`
  margin-top: 25px;
`

export default function App() {
  const {
    checkoutId,
    typeSale = 'compra',
    titleURI = null,
    descriptionURI = null,
    logoURI = null,
    logoHeightURI = null,
    hasLogo = 1,
    merchantId: searchMerchantId,
    callbackURI = null,
    isFrame = false,
  } = getParams(window.location.search)

  // If you are deploying your own checkout page the merchantId comes from the ENV
  const merchantId = process.env.PREACT_APP_MERCHANT_ID || searchMerchantId

  const { isLoading, data, error } = usePromise(
    () =>
      VannaService.getCheckout({
        checkoutId,
        merchantId,
      }),
    [checkoutId],
  )

  const [context, setContext] = useState({})
  const updateFormData = useCallback(
    (obj) => {
      const update = { ...context, ...obj }
      setContext(update)
      return update
    },
    [context],
  )

  useEffect(() => {
    updateFormData({ merchantId, ...(data ? { isLoaded: true } : {}), ...data })
  }, [merchantId, data])

  useEffect(() => {
    if (isFrame && window.parent) {
      postMessage({
        action: 'INITIALIZED',
      })
    }
  }, [])

  return (
    <>
      <Helmet
        title={titleURI || `${data?.company?.productName || ''} - Checkout`}
        meta={[
          { name: 'description', content: descriptionURI || `${data?.company?.productName} - Checkout` },
          { property: 'og:title', content: descriptionURI || `${data?.company?.productName} - Checkout` },
          { property: 'og:type', content: 'website' },
          {
            property: 'og:image',
            content: logoURI || data?.company?.logoSrc,
          },
        ]}
        link={[
          { rel: 'icon', type: 'image/png', href: logoURI || data?.company?.iconSrc },
          { rel: 'shortcut icon', type: 'image/png', href: logoURI || data?.company?.iconSrc },
          { rel: 'apple-touch-icon', type: 'image/png', href: logoURI || data?.company?.iconSrc },
        ]}
      >
        <script type="text/javascript">{`
        (function() {
        window.__insp = window.__insp || [];
        __insp.push(['wid', 680205993]);
        var ldinsp = function(){
        if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=680205993&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
        setTimeout(ldinsp, 0);
        })();
        `}</script>
      </Helmet>

      <HeaderSecurity />
      <Match path="/sucesso">
        {({ matches, path }) => {
          if (error) return <Wrapper>Algo deu errado, tente novamente!</Wrapper>
          if (isLoading || !context.isLoaded) return <Wrapper>Aguarde...</Wrapper>

          return (
            <CheckoutContext.Provider value={{ parentData: context, updateFormData }}>
              <LayoutCheckout
                title={
                  matches
                    ? context.paymentType === 'BANKSLIP'
                      ? 'Parabéns, boleto emitido com sucesso!'
                      : 'Parabéns, pagamento realizado com sucesso!'
                    : titleURI
                }
                companyLogoSrc={logoURI || context?.company?.logoSrc}
                companyLogoHeight={logoHeightURI}
                items={context.checkout.items}
                canEditQuantity={path === '/'}
                typeSale={typeSale}
                hasLogo={hasLogo}
                snippets={context?.checkout?.snippets}
                bannerLink={context?.checkout?.bannerLink}
              >
                <Router>
                  <LazyRoute
                    path="/"
                    getComponent={() => import('./pages/CheckoutStart').then((module) => module.CheckoutStart)}
                  />
                  <LazyRoute
                    path="/sucesso"
                    getComponent={() => import('./pages/CheckoutSuccess').then((module) => module.CheckoutSuccess)}
                    callbackURI={callbackURI}
                    isFrame={isFrame}
                  />
                </Router>
              </LayoutCheckout>
            </CheckoutContext.Provider>
          )
        }}
      </Match>
    </>
  )
}
