import { createContext } from 'preact'

export const CheckoutContext = createContext({
  company: {},
  checkout: {},
  paymentType: null,
  paymentData: {},
  personalInfo: {},
  itemsDetails: [],
  merchantId: null,
  isLoaded: false,
  checkoutResponse: null,
})
