import AsyncRoute from 'preact-async-route'
import styled from 'styled-components'
import queryString from 'query-string'

const Wrapper = styled.div`
  margin-top: 25px;
  text-align: center;
`

export function LazyRoute({ path, getComponent, matches, ...props }) {
  return (
    <AsyncRoute
      path={path}
      getComponent={getComponent}
      loading={() => <Wrapper>Aguarde...</Wrapper>}
      matches={matches}
      stringQueryParams={queryString.stringify(matches)}
      {...props}
    />
  )
}
