import styled from 'styled-components'
import { useState, useEffect, useContext } from 'preact/hooks'

import { CheckoutContext } from '../CheckoutContext'

import { maskMoney } from '../fn/MaskMoney'
import { Paper } from './Paper'

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
`

const TableComponent = styled.table`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
`

const Wrapper = styled(Paper)`
  padding: 15px 20px;
`

const Title = styled.h4`
  text-weight: bold;
  padding-bottom: 25px;
`

const TableHeadCell = styled.th`
  text-align: left;
  padding: 0 0 1rem;

  &:first-child {
    padding-left: 10px;
  }
  &:last-child {
    padding-right: 10px;
  }
`

const TableCell = styled.td`
  padding-bottom: 15px;
  font-weight: ${(props) => (props.bold ? 'bold' : 400)};

  &:last-child {
    padding-bottom: 0px;
  }
`

const Select = styled.select`
  width: 50%;
`

function getTotal(items, itemQuantities) {
  return items.reduce((acc, cur) => {
    const quantity = itemQuantities[cur._id]

    return acc + cur.price * quantity
  }, 0)
}

const DEFAULT_MAX_QUANTITY = 100

export function PaymentDetailsCard({ items = [], editable = false, typeSale }) {
  const { parentData, updateFormData } = useContext(CheckoutContext)
  const [itemQuantities, setItemQuantities] = useState({})

  useEffect(() => {
    setItemQuantities(
      items.reduce((acc, cur) => {
        const detail = (parentData.itemsDetails || []).find((item) => item._id === cur._id)

        const quantity = detail ? detail.quantity : 1

        return {
          ...acc,
          [cur._id]: quantity,
        }
      }, {}),
    )
  }, [])

  useEffect(() => {
    const ids = Object.keys(itemQuantities)

    updateFormData({
      totalAmount: getTotal(items, itemQuantities),
      itemsDetails: ids.map((id) => ({ _id: id, quantity: itemQuantities[id] })),
    })
  }, [itemQuantities])

  if (!items.length) return null

  return (
    <Paper>
      <Wrapper>
        <Title>Detalhes da sua {typeSale}:</Title>
        <TableWrapper>
          <TableComponent>
            <thead>
              <tr>
                <TableHeadCell>Item</TableHeadCell>
                <TableHeadCell>Quantidade</TableHeadCell>
                <TableHeadCell>Valor Unitário</TableHeadCell>
                <TableHeadCell>Valor Total</TableHeadCell>
              </tr>
            </thead>
            <tbody>
              {items.map(({ _id, name, price, maxQuantity }) => {
                const quantity = itemQuantities[_id]

                return (
                  <tr>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {editable ? (
                        <Select
                          value={itemQuantities[_id]}
                          onChange={(event) =>
                            setItemQuantities({
                              ...itemQuantities,
                              [_id]: Number(event.target.value),
                            })
                          }
                        >
                          {new Array(maxQuantity || DEFAULT_MAX_QUANTITY).fill(0).map((_, idx) => {
                            const number = idx + 1

                            return (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            )
                          })}
                        </Select>
                      ) : (
                        quantity
                      )}
                    </TableCell>
                    <TableCell>{maskMoney(price)}</TableCell>
                    <TableCell>{maskMoney(price * quantity)}</TableCell>
                  </tr>
                )
              })}
              <tr>
                <td />
                <td />
                <TableCell bold>Total</TableCell>
                <TableCell bold>{maskMoney(getTotal(items, itemQuantities))}</TableCell>
              </tr>
            </tbody>
          </TableComponent>
        </TableWrapper>
      </Wrapper>
    </Paper>
  )
}
