import { useEffect } from 'preact/hooks'
import styled from 'styled-components'

import { PaymentDetailsCard } from '../components/PaymentDetailsCard'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
`

const CompanyLogo = styled.img`
  height: ${(props) => props.height || '75px'};
  margin: 40px 0;
  align-self: center;
`

const ChildrenWrrapper = styled.div`
  margin-top: 20px;
`

const BannerWrapper = styled.div`
  max-height: 400px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`

const BannerImage = styled.img`
  width: 100%;
`

export function LayoutCheckout({
  title,
  items,
  companyLogoSrc,
  companyLogoHeight,
  canEditQuantity,
  typeSale,
  hasLogo,
  children,
  bannerLink,
  snippets = '',
}) {
  useEffect(() => {
    eval(snippets)
  }, [snippets])

  return (
    <Wrapper>
      {title && <h1 className="title">{title}</h1>}
      {!bannerLink && Number(hasLogo) ? <CompanyLogo height={companyLogoHeight} src={companyLogoSrc} /> : <br />}
      {bannerLink && (
        <BannerWrapper>
          <BannerImage src={bannerLink} />
        </BannerWrapper>
      )}
      <PaymentDetailsCard items={items} editable={canEditQuantity} typeSale={typeSale} />
      <ChildrenWrrapper>{children}</ChildrenWrrapper>
    </Wrapper>
  )
}
